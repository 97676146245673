<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'
import { getArApApi } from '@/api/cash'
import { autoComplete } from '@/api/misc'

// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import EftPaymentDialog from './components/eft_payment_dialog.vue'
import PrintChequeDialog from './components/print_cheque_dialog.vue'
import PaymentEditDialog from "./components/edit_transaction_payment_dialog.vue";

/**
 * User list component
 */
export default {
  page: {
    title: "General Account Mgt",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "General Account Mgt",
      items: [
        {
          text: "Account Payable",
          href: "/",
        },
        {
          text: "General Account Mgt",
          href: "/",
          active : true
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: {Supplier : ''},
      filterOn: ['type'],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "G",
          label : '',
          sortable: true,
          tdClass: 'align-middle' 
        },

       

        {
          key: "Payee",
          sortable: true,
          tdClass: 'align-middle' 
        },

        {
          key: "PostDate",
          sortable: false,
          tdClass: 'align-middle' 
        },
        {
          key: "Amount",
          sortable: true,
          tdClass: 'align-middle' 
        },

        {
          key: "PayType",
          sortable: true,
          tdClass: 'align-middle' 
        },
        
        {
          key: "Invoice",
          sortable: true,
          tdClass: 'align-middle' 
        },
        

        {
          key: "Status",
          sortable: false,
          tdClass: 'align-middle' 
        },
        {
          key: "Action",
          sortable: false,
          tdClass: 'align-middle' 
        },

        
      ],
      rangeOptions: {
        mode: "range",
      },
     
      searched_data : [],
      search_str    : '',
      pay_type : '',
      query_type : 'GENERAL_EXPENSE',
      query_agent_id: '',
      query_deal_id: '',
      queryRange : '',
      query_status : 'PENDING',
      selected_transactions: [],
      cheque_list :  [],
      eft_list    :   [],
      trans_pay_type : '',
      filter_transaction_list: [],
      total_amount : 0,
      current_transaction : {},
    };
  },
  components: {
    Layout,
    PageHeader,
    //flatPickr,
    EftPaymentDialog,
    PrintChequeDialog,
    VueBootstrapTypeahead,
    PaymentEditDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

    cheque_btn_disabled_flag() {
      let progress = this.selected_transactions.filter(e => e.status == 'PENDING PAYMENT')
      return progress.length > 0 ? false : true
     
    },

    approve_btn_disabled_flag() {
      let progress = this.selected_transactions.filter(e => e.status == 'PENDING')
      return progress.length != this.selected_transactions.length || progress.length <= 0
    },

    eft_btn_disabled_flag() {
      let progress = this.selected_transactions.filter(e => e.status == 'PENDING PAYMENT' && e.t.type == 'EFT')

      if (progress.length != this.selected_transactions.length || progress.length.length <= 0 || this.selected_transactions.length <= 0) {
        return true
      } else {
        return false
      }
    }
  },

  created() {
    this.queryList()
  },
  mounted() {

    new Choices('#status_choice', {
    });

  //  new Choices('#type_choice')

  },
  methods: {

    queryList(invoice, supplier_id, agent_id) {

      let data = {
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        },
        query_type : this.query_type,
        pay_type : this.pay_type,
        status : this.query_status,
      }

      if (invoice) {
        data.query_invoice = invoice
      } else if (supplier_id) {
        data.query_supplier_id = supplier_id
      } else if (agent_id) {
        data.query_agent_id = agent_id
      }

      getCashApi().ap_list(data).then((res) => {
        this.list = []
        res.data.map((o) => {
          this.list.push(o)
          this.totalRows = res.page.total;

        })

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
        this.filter_transaction_list = filteredItems

        this.total_amount = this.filter_transaction_list.reduce((a, c) => a + Number(c.amount), 0)
    },


    onShowEFT(evt) {
      let supplier = this.filter.Supplier
      if (evt.target.checked == true) {

        this.filter = {PayType : 'EFT', Supplier:  supplier}
      } else {
        this.filter = {Supplier:  supplier}
      }
    },

    filterTable(row, filter) {
      if (filter.PayType && filter.Supplier) {
        return row.type == filter.PayType  && row.payee.startsWith(filter.Supplier)
      } else if (filter.PayType ) {
        return row.payee_pay_type == filter.PayType 
      }  else if (filter.Supplier) {
        return row.payee.startsWith(filter.Supplier)
      } else {
        return true
      }
    },


    onSelectAll(evt) {
      if (evt.target.checked == true) {
        this.selected_transactions = this.filter_transaction_list
      } else {
        this.selected_transactions = []
      }

      this.filter_transaction_list.map(t => t.checked = evt.target.checked)
    },


    onSelectedTransaction(evt, t)  {
      if (evt.target.checked == true) {
        let d = this.selected_transactions.find((e)=> e.id == t.id)
        if (!d) {
          this.selected_transactions.push(t)
        }

      } else {
        this.selected_transactions = this.selected_transactions.filter((e)=> e.id != t.id)
      }

      let cheque_cflag  = this.selected_transactions.some(t =>t.type =='CHEQUE')
      let eft_cflag  = this.selected_transactions.some(t =>t.type =='EFT')
      if (cheque_cflag && eft_cflag) {
        this.trans_pay_type = 'MISC'
      } else if (eft_cflag) {
        this.trans_pay_type = 'EFT'
      } else if (cheque_cflag) {
        this.trans_pay_type = 'CHEQUE'
      }
    },

    onEftSetup() {
      this.selected_transactions.map(e => e.status ='PROGRESS')
      this.$bvModal.hide('modal-eft-payment-dialog')
      this.$alertify.message("Setup EFT Success  ");

      this.queryList();
      this.selected_transactions = []
    },

    onPrinted() {
     
      this.selected_transactions.map(t => {
        if (t.job_id) {
          t.status = 'PROGRESS'
        }
       
      })
      
      this.$bvModal.hide('modal-cheque-payment-dialog')
    },

    onCancelPayment(item, payment) {
     
     getArApApi().cancel_transaction({trans_id : payment.id, trans_type : 'EXPENSE'}).then(res => {
       if (res.errCode == 0) {
         this.list.splice(item.index, 1)
       } else {
         this.$alertify.error("Cancel Payment Failed  ret:" + res.errCode);
       }
     })
   },

   onPaymentEdit(p) {
      console.log(p)
      let data = {
        trans_id  : this.current_transaction.id,
        new_payemnt_type      : p.type,
        payee     : p.payee,
        address   : p.address,
        eft       : {
          inist     : p.inst,
          transit   : p.transit,
          account   : p.account,
        }

      }
      getArApApi().edit_transaction_type(data).then(res => {
        if (res.errCode == 0) {
         
          this.$alertify.message("Edit Success" );
          this.$bvModal.hide('modal-payment-eidt-dialog')
        } else {
            this.$alertify.error("Failed to Edit ret:" + res.errCode);
          }
      })
    },


    editPaymentType(item, expense) {
      this.current_transaction = expense.t
      this.$bvModal.show('modal-payment-eidt-dialog')
     
    },

    list_sort() {
      this.list.sort((a, b) => {
        if (this.sortBy == 'Amount') {
          if (this.sortDesc) {
            return a.amount > b.amount ? -1 : 1
          } else {
            return a.amount > b.amount ? 1 : -1
          }
        } 
      })
    },

    getFootValue(item) {
     
     if (item.label =='Amount') {
       return '$' + this.total_amount.toLocaleString()
     } else if (item.label == 'Post Date') {
       return 'Total: '
     } else {
       return ''
     }
   
    },
    onApprove() {
      let list = []

      this.selected_transactions.map(e => {
        list.push(e.id)
      })
      let data = {
        expenses : list
      }
      getArApApi().approve_invoice_payment(data).then(res => {
        if (res.errCode == 0) {
          this.queryList()
        } else {
          this.$alertify.error("Failed to Approve:"+res.errCode)
        }
      })
    },

    showEFTDialog() {
      this.eft_list = []
      this.selected_transactions.map(e => {
        if (e.t.type == 'EFT') {
          e.t.expense_id = e.id
          this.eft_list.push(e.t)
        }
      })

      this.$bvModal.show('modal-eft-payment-dialog')
    },

    showChequePrintDialog() {
      this.cheque_list = []
      this.selected_transactions.map(e => {
        if (e.t) {
          e.t.expense_id = e.id
          this.cheque_list.push(e.t)
        }
      })

      this.$bvModal.show('modal-cheque-payment-dialog')
    },


    onSearch(txt) {
      
      if (txt == '') {
        this.queryList()
        return;
      }

      this.searched_data = []
      autoComplete({types : ['EXPENSE_INVOICE', 'SEARCH_SUPPLIER'], str : txt}).then(res =>{
        if (res.errCode == 0) {
          this.searched_data = []
          res.data.map(e => {
            this.searched_data.push({
              str_id    : e.str_id,
              s_type    : e.s_type,
              obj       : e
            })
          })
         
        }
      })
    },

    onSearchedSelected(evt) {
      console.log(evt)
      if (evt.s_type == 'EXPENSE_INVOICE') {
        this.queryList(evt.str_id)
      } else {
        this.queryList('', evt.obj.id)
      }
    },

    get_payment_type(expense) {
      if (expense.t) {
        return expense.t.type
      } else {
        return 'N/A'
      }
    }



  },



  watch: {
    sortBy () {
      this.list_sort()
    },

    sortDesc () {
      this.list_sort()
    },


    search_str(search_str) {
      this.onSearch(search_str)
    },
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal centerd id="modal-eft-payment-dialog" title="EFT" size="lg" hide-footer>
      <EftPaymentDialog :transactions="eft_list" :bank_account="'General Account'" @cancel="$bvModal.hide('modal-eft-payment-dialog')" @confirm="onEftSetup()"  />
    </b-modal>

    <b-modal centerd id="modal-cheque-payment-dialog" title="Cheque Print" size="lg" hide-footer>
      <PrintChequeDialog :transactions="cheque_list" :bank_account="'General Account'"  @cancel="onPrinted" @confirm="$bvModal.hide('modal-cheque-payment-dialog')"  />
    </b-modal>

    <b-modal centerd id="modal-payment-eidt-dialog" title="Payment Type Edit" size="lg" hide-footer>
      <PaymentEditDialog :transaction="current_transaction"  @cancel="$bvModal.hide('modal-payment-eidt-dialog')" @confirm="onPaymentEdit"  />
    </b-modal>



    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row">

              <div class="col-md-2">
                <select name="choices-multiple-remove-button" id="status_choice"  v-model="query_status" @change="queryList()">
                  <option value="PENDING" selected>Pending</option>
                  <option value="PENDING PAYMENT">Approved</option>
                  <option value="PROGRESS">Progress</option>
                  <option value="PAID">Completed</option>
                  <option value="CANCELLED">Cancelled</option>
                </select>

              </div>

              <div class="col-md-2">
                <vue-bootstrap-typeahead ref="expense_search_ref"  
                              v-model="search_str"  :data="searched_data"
                              :serializer="s =>s.s_type == 'EXPENSE_INVOICE'? 'Invoice:' +s.str_id: 'Payee:' + s.str_id"
                              placeholder="Search by Supplier or Invoice"
                              :foramterDisplay="s => s.str_id " @hit="onSearchedSelected($event)" autocomplete="off" />
              </div>


              <div class="col-md-1 mb-3 mt-2 d-flex flex-wrap gap-2">
                <input type="checkbox" class="form-check-control" @change="onShowEFT"/>EFT Only
               
              </div>


              <div class="col-md-7 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">

                <b-button variant="info" :disabled="eft_btn_disabled_flag" @click="showEFTDialog">EFT</b-button>
                <b-button variant="info" :disabled="cheque_btn_disabled_flag"  @click="showChequePrintDialog">Cheque</b-button>
                <b-button variant="info" :disabled="approve_btn_disabled_flag" @click="onApprove">Approve</b-button>
                <b-dropdown  split  text="More"    variant="light"  >

                  <b-dropdown-item @click="$router.push({name : 'ap-create-expnese'})">Setup Invoice</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name : 'ap-create-rebgv-expnese'})">Setup REBGV Invoice</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name : 'ap-create-western-expnese'})">Setup Western Invoice</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name : 'ap-tax-payment'})">Pay GST Tax</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({name : 'ap-create-recoverable-expense'})">Break Down Invoice</b-dropdown-item>
                  
                  <b-dropdown-item v-b-modal.export_dialog>Export </b-dropdown-item>
                </b-dropdown>
                <!-- end dropdown -->
              </div>
              <!-- end col -->

             
              </div>

          </div>
          <div class="card-body">
 



            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"  @filtered="onFiltered"
                  :filter-function="filterTable"
                  striped hover
                  class="table-check datatables" foot-clone>

                  <template #head(G)>
                    <input type="checkbox" @change="onSelectAll($event)"/>
                  </template>

                  <template #cell(G)="data">
                    <input type="checkbox" v-model="data.item.checked" @change="onSelectedTransaction($event, data.item)"/>
                   
                  </template>
                

                  <template #cell(Payee)="data">
                    {{ data.item.payee_name }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(PostDate)="data">
                    {{ data.item.due_date }}
                  </template>

                  <template #cell(Invoice)="data">
                      {{ data.item.invoice }}
                  </template>


                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(PayType)="data">
                    {{ get_payment_type(data.item) }}
                  </template>
                  <template #cell(Remark)="data">
                    {{ data.item.remarks }}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown
                      variant="white"
                      right
                      toggle-class="btn-link text-dark shadow-none"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>

                      <b-dropdown-item :disabled=" data.item.status != 'PENDING'" @click="$router.push({name :'ap-edit-expnese', query: {expense_id: data.item.id}})">Edit</b-dropdown-item>
                      <b-dropdown-item :disabled=" data.item.status != 'PENDING'" @click="onCancelPayment(data, data.item)">Cancel Payment</b-dropdown-item>
                      <b-dropdown-item :disabled=" data.item.status != 'COMPLETED'" @click="$router.push({name : 'ap-create-recoverable-expense'})">Break down Invoice</b-dropdown-item>
                      <b-dropdown-item :disabled="data.item.status != 'PENDING PAYMENT'" @click="editPaymentType(data, data.item)">Edit Payment Type</b-dropdown-item>
                     
                    </b-dropdown>

                  </template>

                 
                  <template #foot()="data">
                    <i>{{ getFootValue(data) }}</i>
                  </template>

                  <template v-slot:row-details="row">
                    <div class="table align-middle table-nowrap" style="margin-left:30px;">
                      <b-table
                        :items="row.item.details"
                        :fields="[{key : 'Type'},{key : 'SubType'},{key :'Amount'},{key : 'Remarks'}]"
                        responsive="sm"
                        :per-page="row.item.details.length"

                        class="table-check col-md-10"
                      >
                        <template  #cell(Type)="data">
                          {{ data.item.detail_category }}
                        </template>
                        <template  #cell(SubType)="data">
                          {{ data.item.detail_type }}
                        </template>
                        <template  #cell(Amount)="data">
                          ${{ data.item.detail_amount.toLocaleString() }}
                        </template>
                        <template  #cell(Remarks)="data">
                          {{ data.item.remarks }}
                        </template>
                       
                      </b-table>
                    </div>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
